import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

/* ***Layouts**** */
const FullLayout = lazy(() => import('../layouts/full-layout/FullLayout'));
const BlankLayout = lazy(() => import('../layouts/blank-layout/BlankLayout'));
/* ***End Layouts**** */

const Error = lazy(() => import('../views/authentication/Error'));
const Login = lazy(() => import('../views/authentication/Login'));
const Register = lazy(() => import('../views/authentication/Register'));
const ResetPassword = lazy(() => import('../views/authentication/ResetPassword'));

/* ****Pages***** */
const Dashboard1 = lazy(() => import('../views/dashboards/Dashboard1'));
const Detail = lazy(() => import('../views/shipments/detail'));
const CreateShipment = lazy(() => import('../views/quote/createShipment'));
const Quote = lazy(() => import('../views/quote/quote'));
const Address = lazy(() => import('../views/quote/address'));
const Confirmation = lazy(() => import('../views/quote/confirmation'));
const Payments = lazy(() => import('../views/payments/payments'));
const Clients = lazy(() => import('../views/clients/clients'));
const ClientDetail = lazy(() => import('../views/clients/detail'));
const DetailBalance = lazy(() => import('../views/clients/detailBalance'));
const Folios = lazy(() => import('../views/courier/folios'));
const PriceGroups = lazy(() => import('../views/courier/priceGroups'));
const Shipments = lazy(() => import('../views/shipments/shipments'));
const Profile = lazy(() => import('../views/profile/'));
const Users = lazy(() => import('../views/users/'));
const AddressList = lazy(() => import('../views/address/address'));
const Partners = lazy(() => import('../views/partners'));

/* ****Routes***** */

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to={localStorage.session !== undefined ? "/dashboard" : "/auth/login"} /> },
      { path: '/dashboard', exact: true, element: <Dashboard1 /> },
      { path: '/quote', exact: true, element: <Quote /> },
      { path: '/create', exact: true, element: <CreateShipment /> },
      { path: '/quote/address', exact: true, element: <Address /> },
      { path: '/clients', exact: true, element: <Clients /> },
      { path: '/client/detail/:clientId', exact: true, element: <ClientDetail /> },
      { path: '/client/detail/:clientId/balance', exact: true, element: <DetailBalance /> },
      { path: '/courier/redpack', exact: true, element: <Folios /> },
      { path: '/courier/price-groups', exact: true, element: <PriceGroups /> },
      { path: '/shipments', exact: true, element: <Shipments /> },
      { path: '/quote/:shipmentNumber', exact: true, element: <Confirmation /> },
      { path: '/shipments/:shipmentStatus', exact: true, element: <Shipments /> },
      { path: '/shipment/detail/:shipmentNumber', exact: true, element: <Detail /> },
      { path: '/pickup', exact: true, element: <Dashboard1 /> },
      { path: '/address/:clientId', exact: true, element: <AddressList /> },
      { path: '/payments', exact: true, element: <Payments /> },
      { path: '/packages', exact: true, element: <Dashboard1 /> },
      { path: '/profile', exact: true, element: <Profile /> },
      { path: '/store', exact: true, element: <Dashboard1 /> },
      { path: '/users', exact: true, element: <Users /> },
      { path: '/partners', exact: true, element: <Partners /> },

      // { path: '/dashboards/dashboard2', exact: true, element: <Dashboard2 /> },
      // { path: '/dashboards/dashboard3', exact: true, element: <Dashboard3 /> },
      // { path: '/customers/lists', exact: true, element: <CustomerLists /> },
      // { path: '/chats', element: <Chats /> },
      // { path: '/notes', element: <Notes /> },
      // { path: '/email', element: <Email /> },
      // { path: '/shop/lists', element: <Shop /> },
      // { path: '/calendar', element: <Calendar /> },
      // { path: '/customers/edit', element: <CustomerEdit /> },
      // { path: '/tables/basic-table', element: <BasicTable /> },
      // { path: '/tables/pagination-table', element: <PaginationTable /> },
      // { path: '/tables/enhanced-table', element: <EnhancedTable /> },
      // { path: '/tables/collapsible-table', element: <CollapsibleTable /> },
      // { path: '/tables/fixed-header-table', element: <FixedHeaderTable /> },
      // { path: '/form-layouts/form-layouts', element: <FormLayouts /> },
      // { path: '/form-elements/autocomplete', element: <ExAutoComplete /> },
      // { path: '/form-elements/button', element: <ExButton /> },
      // { path: '/form-elements/checkbox', element: <ExCheckbox /> },
      // { path: '/form-elements/date-time', element: <ExDateTime /> },
      // { path: '/form-elements/radio', element: <ExRadio /> },
      // { path: '/form-elements/slider', element: <ExSlider /> },
      // { path: '/form-elements/switch', element: <ExSwitch /> },
      // { path: '/form-layouts/form-wizard', element: <FormWizard /> },
      // { path: '/widgets/widget-feed', element: <WidgetFeed /> },
      // { path: '/widgets/widget-apps', element: <WidgetApps /> },
      // { path: '/user-profile', element: <UserProfile /> },
      // { path: '/shop/shop-detail', element: <ShopDetail /> },
      // { path: '/charts/line-chart', element: <LineChart /> },
      // { path: '/charts/gredient-chart', element: <GredientChart /> },
      // { path: '/charts/doughnut-pie-chart', element: <DoughnutChart /> },
      // { path: '/charts/area-chart', element: <AreaChart /> },
      // { path: '/charts/column-chart', element: <ColumnChart /> },
      // { path: '/charts/candlestick-chart', element: <CandlestickChart /> },
      // { path: '/charts/radialbar-chart', element: <RadialbarChart /> },
      // { path: '/react-icons', element: <ReactIcons /> },
      // { path: '/form-layouts/form-custom', element: <FormCustom /> },
      // { path: '/quill-editor', element: <QuillEditor /> },
      // { path: '/treeview', element: <Treeview /> },
      // { path: '/pricing', element: <Pricing /> },
      // { path: '/timeline', element: <CustomTimeline /> },
      // { path: '/typography', element: <CustomTypography /> },
      // { path: '/alert', element: <ExAlert /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
